<template>
    <v-card class="mx-auto" elevation="4" outlined style="display: flex; align-items: center;">
        <v-progress-linear
            v-if="progress"
            style="position: absolute; top: 0px; left: 0px;"
            :value="progress"
            height="10"
            :color="progressColor"
        >
            <strong style="font-size: 11px;">{{ Math.ceil(progress) }}%</strong>
        </v-progress-linear>
        <v-list-item three-line>
            <v-list-item-avatar tile :color="color" size="70" rounded class="br-5">
                <v-icon x-large color="white">{{ icon }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
                <div class="text-overline subtitle-2 mb-2">
                    {{ label }}
                </div>
                <v-list-item-title class="monetary mb-1">
                    {{ value }}
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-card>
</template>

<script>
export default {
    name: 'Card',

    props: {
        value: {
            type: [String, Number],
            defalut: '',
        },
        label: {
            type: String,
            defalut: '',
        },
        color: {
            type: String,
            defalut: '',
        },
        icon: {
            type: String,
            defalut: '',
        },
        progress: {
            type: [String, Number],
            defalut: '',
        },
        progressColor: {
            type: String,
            defalut: '',
        },
    },
}
</script>

<style scoped>
.br-5 {
    border-radius: 5px !important;
}

.monetary {
    font-weight: 600;
    font-size: 26px;
}

.v-card {
    padding: 0 15px 0 15px;
}

.text-overline {
    font-weight: 600;
    letter-spacing: 0.1em !important;
}
</style>